<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Color</h1>
              <!-- <div class="pagehead-link">
              <a href=""><img src="/html-components/images/svg/link.svg" alt=""></a>
            </div> -->
            </div>
            <p>
              The Ohio Design System’s color system is based on the Ohio brand
              color palette, with maroon and bright red as the primary colors.
              These colors are warm, energetic, and straightforward, and
              effectively communicate the distinctiveness of the Ohio brand.
            </p>
          </div>
        </section>

        <div class="divider max-w1200"></div>

        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1200">
            <section>
              <div class="content-band">
                <h2>General Guidelines</h2>
                <ul class="bullet-red">
                  <li>
                    Color helps to create consistent and seamless digital
                    experiences across the State, and can be used.
                  </li>
                  <li>
                    To indicate a hierarchy within text, separating headings,
                    lead-in paragraphs, block quotes.
                  </li>
                  <li>
                    To direct attention to primary and secondary calls to
                    action, links, and messages like errors and alerts.
                  </li>
                  <li>
                    To direct users scanning a page – color density leads the
                    user to focal points, while shading backgrounds and borders
                    helps set the tone.
                  </li>
                </ul>
              </div>
            </section>

            <div class="p-30 gray-250 max-w800">
              <div class="font-red semibold">
                Important Note About Accessibility
              </div>
              <p>
                It is very important that colors follow the WCAG 2.0 color ratio
                guidelines to ensure users that have color-blindness or struggle
                with contrast can successfully navigate the State of Ohio
                digital touchpoints. In addition, it is of the utmost importance
                that color is never used as the only indicator. Consider
                including an additional element such as a line, a dot, etc. to
                assist users to identify various states of the UI (active,
                current, etc.)
              </p>
            </div>

            <section id="Structural-colors">
              <div class="content-band" id="structural-color">
                <h3>Structural Colors</h3>
                <p>
                  Structural colors are the basic foundations of the design.
                  These apply to page/section backgrounds, text colors, and
                  other utility functions like navigation icons, as well as
                  secondary or tertiary calls to action.
                </p>

                <p class="mt-30">Neutral Grays Pallet</p>

                <div class="max-w800">
                  <div class="flex-grid basis-20 gap-2 mb-20">
                    <ColorBlock name="gray-800" color="#2C2C2C" />
                    <ColorBlock name="gray-700" color="#434343" />
                    <ColorBlock name="gray-600" color="#8C8C8C" />
                    <ColorBlock name="gray-500" color="#BFBFBF" />
                    <ColorBlock name="gray-400" color="#D9D9D9" />
                    <ColorBlock name="gray-300" color="#E8E8E8" />
                    <ColorBlock name="gray-200" color="#F2F2F2" />
                    <ColorBlock name="gray-100" color="#F6F6F6" />
                  </div>
                </div>
              </div>
            </section>

            <div class="divider"></div>

            <section id="Primary-colors">
              <div class="content-band" id="primary-color">
                <h3>Primary Colors</h3>
                <p>
                  Primary colors are the foundational colors of the branded
                  elements and calls to action. The main purpose of primary
                  colors is to infuse the feeling of cohesiveness across State
                  of Ohio digital touchpoints.
                </p>
                <p class="mt-30">Ohio brand maroon</p>
                <div class="flex-grid basis-20 gap-2 mb-20 max-w800">
                  <ColorBlock name="drk-red-800" color="#700017" />
                  <ColorBlock name="drk-red-700" color="#8b3043" />
                  <ColorBlock name="drk-red-600" color="#99495a" />
                  <ColorBlock name="drk-red-500" color="#aa6d7e" />
                  <ColorBlock name="drk-red-400" color="#bf95a5" />
                  <ColorBlock name="drk-red-300" color="#e9d9dc" />
                  <ColorBlock name="drk-red-200" color="#f5eeee" />
                  <ColorBlock name="drk-red-100" color="#FAF6F6" />
                </div>
                <p class="mt-40">Ohio brand red</p>
                <div class="flex-grid basis-20 gap-2 mb-20 max-w800">
                  <ColorBlock name="red-800" color="#EA1014" />
                  <ColorBlock name="red-700" color="#f1353a" />
                  <ColorBlock name="red-600" color="#f4595c" />
                  <ColorBlock name="red-500" color="#f57174" />
                  <ColorBlock name="red-400" color="#f7888b" />
                  <ColorBlock name="red-300" color="#f9acae" />
                  <ColorBlock name="red-200" color="#fbc4c5" />
                  <ColorBlock name="red-100" color="#fde7e7" />
                </div>
                <p>
                  To be WCAG AA complaint for small text, Ohio brand red has been shifted from <span class="text-hilight--red">#EF1217</span> to <span class="text-hilight--red">#EA1014.</span>
                </p>
              </div>
            </section>

            <div class="divider"></div>

            <section id="Secondary-colors">
              <div class="content-band" id="secondary-color">
                <h3>Secondary Colors</h3>
                <p>
                  Secondary colors should be used to set the tone and mood of a
                  page or site. A range of colors has been chosen to complement
                  the Ohio Brand primary colors, adding personality and
                  flexibility to the palette.
                </p>
                <p class="mt-30">Ohio brand blue</p>
                <div class="flex-grid basis-20 gap-2 mb-20 max-w800">
                  <ColorBlock name="blue-800" color="#3d7aa9" />
                  <ColorBlock name="blue-700" color="#4781ad" />
                  <ColorBlock name="blue-600" color="#5f91b7" />
                  <ColorBlock name="blue-500" color="#749fc1" />
                  <ColorBlock name="blue-400" color="#9ebcd4" />
                  <ColorBlock name="blue-300" color="#c4d7e5" />
                  <ColorBlock name="blue-200" color="#e2ebf2" />
                  <ColorBlock name="blue-100" color="#f4f7fa" />
                </div>
                <p class="mt-40">Ohio brand gold</p>
                <div class="flex-grid basis-20 gap-2 mb-20 max-w800">
                  <ColorBlock name="gold-800" color="#FFBE0E" />
                  <ColorBlock name="gold-700" color="#FFCE4A" />
                  <ColorBlock name="gold-600" color="#FAD87B" />
                  <ColorBlock name="gold-500" color="#F8DE9B" />
                  <ColorBlock name="gold-400" color="#F6E2B2" />
                  <ColorBlock name="gold-300" color="#F4E7CA" />
                  <ColorBlock name="gold-200" color="#F2EBDD" />
                  <ColorBlock name="gold-100" color="#F3F1ED" />
                </div>
                <!-- <p class="mt-40">Dark Blue</p>
          <div class="colors__container">
            <ColorBlock name="drk-blue-800" color="#001A41" />
            <ColorBlock name="drk-blue-700" color="#193054" />
            <ColorBlock name="drk-blue-600" color="#334867" />
            <ColorBlock name="drk-blue-500" color="#4C5E7A" />
            <ColorBlock name="drk-blue-400" color="#7F8CA0" />
            <ColorBlock name="drk-blue-300" color="#A6AFBD" />
            <ColorBlock name="drk-blue-200" color="#CCD1D9" />
            <ColorBlock name="drk-blue-100" color="#E5E8EC" />
          </div> -->
                <p class="mt-40">Ohio brand green</p>
                <div class="flex-grid basis-20 gap-2 mb-20 max-w800">
                  <ColorBlock name="green-800" color="#b5dc10" />
                  <ColorBlock name="green-700" color="#BCDF27" />
                  <ColorBlock name="green-600" color="#C8E647" />
                  <ColorBlock name="green-500" color="#D3EA70" />
                  <ColorBlock name="green-400" color="#DAED87" />
                  <ColorBlock name="green-300" color="#E5F3AC" />
                  <ColorBlock name="green-200" color="#F0F8CF" />
                  <ColorBlock name="green-100" color="#F7FBE7" />
                </div>
                <!-- <p class="mt-40">Dark Green</p>
          <div class="flex-grid basis-20 gap-2 mb-20">
            <ColorBlock name="drk-green-800" color="#5E8000" />
            <ColorBlock name="drk-green-700" color="#6E8C19" />
            <ColorBlock name="drk-green-600" color="#7E9933" />
            <ColorBlock name="drk-green-500" color="#8EA64C" />
            <ColorBlock name="drk-green-400" color="#AEBF7F" />
            <ColorBlock name="drk-green-300" color="#C7D3A6" />
            <ColorBlock name="drk-green-200" color="#DFE6CC" />
            <ColorBlock name="drk-green-100" color="#EFF2E5" />
          </div> -->
              </div>
            </section>

            <div class="divider"></div>

            <section id="Alerts-and-Notifications">
              <div class="content-band">
                <div>
                <h3 id="alerts">Alerts &amp; Notifications</h3>
                <p>
                  The colors of alerts and notifications were selected for their
                  WCAG 2.0 AA color contrast value with white text or white
                  background (for
                  <i>utility-red</i> and <i>utility-green</i>) or dark grey text
                  or background (<i>gray-700</i>) for <i>utility-yellow</i>.
                </p>
                </div>
                <div class="flex-grid basis-20 gap-2 mb-20 max-w800">
                  <ColorBlock name="utility-red" color="#BE0F11" />
                  <ColorBlock name="utility-yellow" color="#FCD56A" />
                  <ColorBlock name="utility-green" color="#238722" />
                </div>

                <div class="gray-250 p-30 max-w800 mb-30">
                  <div class="font-red semibold">
                    Utility Colors
                  </div>
                  <p>
                    Utility colors are reserved for specific functions or
                    interactions within State of Ohio websites and applications.
                  </p>
                </div>
              </div>
            </section>

            <div class="divider"></div>

            <section id="accessibility">
              <div class="content-band" >
                <h3>Accessibility</h3>
                <p>
                  <a
                    class="font-red"
                    target="_blank"
                    href="https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-without-color.html"
                    >WCAG 2.0 Success Criterion 1.4.1</a>
                  states that color is not used as the only visual means of
                  conveying information, indicating an action, prompting a
                  response, or distinguishing a visual element.
                </p>
                <p>
                  As color must never be used as the only indicator, consider 
                  including an additional element such as a line or a dot to assist 
                  users in identifying various states of the user interface such as 
                  active, current, or unavailable.
                </p>
                <p>
                  In terms of 
                  <a
                    class="font-red"
                    target="_blank"
                    href="https://webaim.org/resources/contrastchecker/"
                    >color</a>  
                  contrast, WCAG 2.0 level AA requires a ratio of at least 4.5:1 
                  for normal text and 3:1 for large text. WCAG Level AAA requires a ratio of at 
                  least 7:1 for normal text and 4.5:1 for large text. 
                </p>
                <p>
                  These guidelines ensure users who have visual impairments or color blindness can 
                  successfully read and navigate State of Ohio digital touchpoints.
                </p>
              </div>
            </section>
          </div>

          <StickyTOC />
        </div>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import StickyTOC from "../StickyTOC.vue";
import ColorBlock from "../../components/ColorBlock";
import SubFooter from "../../components/SubFooter";
export default {
  name: "Color",
  components: {
    TopLevelLeftNav,
    StickyTOC,
    ColorBlock,
    SubFooter,
  },
};
</script>
