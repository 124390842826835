<template>
  <div class="color__container">
    <div class="color__item" :style="{ backgroundColor: color }"></div>
    <div class="color__info">
      <div class="color__name">{{ name }}</div>
      <div class="color__separator">|</div>
      <div class="color__hex">{{ color }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorBlock",
  props: ["name", "color"],
};
</script>

